<template>
  <div class="form row">
    <div class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="country" :rules="rules.country">
        <euro-select v-model="form.country" :label="$t('Country') + '*'" :error-messages="errors"
          :options="countriesSelect" searchable></euro-select>
      </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" vid="from_date" :name="$t('From date')" :rules="rules.from_date">
        <date-picker v-model="form.from_date" :overrides="fromDateOverrides" :error-messages="errors"
          :label="$t('From date')">
        </date-picker> </validation-provider>
    </div>
    <div class="col-6">
      <validation-provider v-slot="{ errors }" :name="$t('To date')" vid="to_date" :rules="rules.to_date">
        <date-picker v-model="form.to_date" :overrides="toDateOverrides" :error-messages="errors"
          :label="$t('To date')">
        </date-picker>
      </validation-provider>
    </div>
    <div class="col-12">
      <validation-provider v-slot="{ errors }" name="vat" :rules="rules.vat">
        <euro-input v-model="form.vat" label="vat" :error-messages="errors" :placeholder="form.country ? `${form.country === 'GR' ? 'EL' : form.country}XXXXXXXXXX` : $t('Enter VAT with country code')
        ">
          <template #label>
            <div class="d-flex align-items-center justify-content-between">
              <div>
                {{ $t("VAT") }}*
                <i v-if="viesValidation" v-b-tooltip.hover :title="$t('VIES valid')" :style="{ color: '#00CBA8' }"
                  class="far fa-check-circle"></i>
              </div>
              <i v-if="lastViesValidationDate" id="vies-validation-info" :class="icons.infoCircle"></i>
              <b-tooltip target="vies-validation-info">
                <strong>{{ $t("Last saved VIES validation") }}:</strong>
                <br />
                <span>{{ moment(lastViesValidationDate).format(dateFormat.concat(" HH:mm:ss")) }}</span>
              </b-tooltip>
            </div>
          </template>
        </euro-input>
      </validation-provider>
    </div>
    <div v-if="!optional" class="col-12">
      <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus" :rules="rules.attachment"
        class="w-100">
        <div id="attachmentFile">
          <euro-tus-file-input :label="$t('File attachment')" vid="attachment_tus" :error-messages="errors"
            :disabled="!form?.vat?.length" accept="application/pdf" :hint="$t('Accept .pdf - max size 20MB')"
            :attachment="existingForm.attachment" @change="onAttachmentChange"></euro-tus-file-input>
          <b-tooltip v-if="!form?.vat?.length" target="attachmentFile">
            {{ $t("Enter the VAT number first") }}
          </b-tooltip>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import datePicker from "@/view/components/datePicker/datePicker.vue";
import api from "@/core/services/api.service";
import { mapGetters, mapState } from "vuex";
import { countryChangeSwal } from "@/core/helpers/swal";
import icons from "@/core/config/icons";
const now = new Date();

export default {
  components: {
    datePicker,
  },
  props: {
    existingForm: {
      type: Object,
      default: () => ({}),
    },
    isNotRequired: {
      type: Boolean,
      default: false,
    },
    defaultCountry: {
      type: String,
      default: "",
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      viesValidation: false,
      viesValid: false,
      lastViesValidationDate: null,
      maxDate: now,
      icons,
      moment,
      form: {
        country: null,
        // counterpart: "",
        vat: null,
        from_date: null,
        to_date: null,
        attachment_tus: null,
        // vies_valid: false,
      },
      // counterparts: [],
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    ...mapGetters("user", ["dateFormat"]),

    vatValidatorArgs() {
      return [this.form.country, !this.existingForm];
    },

    rules() {
      return {
        country: {
          required: !this.isNotRequired,
        },
        counterpart: {
          required: !this.isNotRequired,
        },
        vat: {
          required: !this.isNotRequired,
          vat: [...this.vatValidatorArgs],
        },
        attachment: {},
      };
    },

    optionalFields() {
      return !!(this.optional && this.form.vat.length);
    },

    toDateOverrides() {
      return this.form.from_date ? { minDate: moment(this.form.from_date) } : null;
    },
    fromDateOverrides() {
      return this.form.from_date ? { maxDate: moment() } : null;
    },

    stringMaxDate() {
      return moment(this.maxDate, true).format("YYYY-MM-DD");
    },

    countriesSelect() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    }
  },

  watch: {
    defaultCountry: {
      immediate: true,
      handler(val) {
        if (val && !this.form.country) this.form.country = val;
      },
    },
    "form.vat"(value) {
      if (value.length > 9 && this.form.country) {
        api.post("validators/vat_validate/", { value, country: this.form.country }).then((res) => {
          this.viesValidation = res.data.vies_valid ? true : false;
          this.vies_valid = this.viesValidation;
          this.form.last_vies_validation_date = moment().format("YYYY-MM-DDThh:mm");
        });
      } else {
        this.viesValidation = false;
        this.form.vies_valid = false;
        this.form.last_vies_validation_date = null;
      }
    },
    'form.country': {
      handler(newVal, oldVal) {
        //control the swal for dont do loop
        if (this.isChangingCountry) {
          this.isChangingCountry = false;
          return;
        }
        if (newVal !== null) {
          if (this.existingForm.hasOwnProperty('country') && this.existingForm.country != newVal) {
            countryChangeSwal({
              text: this.$t("You are about to change the country. Are you sure?"),
            }).then(res => {
              if (!res.isConfirmed) {
                this.isChangingCountry = true;
                this.form.country = oldVal;
              }
            });
          }
        }
      },
      inmediate: true
    },
  },

  async mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
      this.viesValid = this.existingForm.vies_valid;
      this.lastViesValidationDate = this.existingForm.last_vies_validation_date;
    }
  },

  methods: {
    onAttachmentChange(e) {
      this.form.attachment_tus = e;
    },
    updateForm() {
      this.$emit('input', this.form);
    }
  },
};
</script>
