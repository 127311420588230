<template>
  <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
    <b-modal id="create-vat-modal" hide-footer @hidden="onModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <template v-if="isEdit">
            <h4 class="mb-0">{{ $t("Update VAT") }}</h4>
          </template>
          <template v-else>
            <h4 class="mb-0">{{ $t("Create VAT") }}</h4>
          </template>
          <div class="d-flex align-items-center">
            <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
              @click="handleSubmit(submit)">
              {{ $t("Save") }}
            </b-button>
            <b-button @click="closeModal">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </div>
      </template>

      <legal-person-vat-form ref="formComponent" :default-country="legalPerson.country_of_establishment"
        :existing-form="existingForm" @input="onLegalPersonVatFormInput"></legal-person-vat-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import LegalPersonVatForm from "@/view/components/forms/legal-person/LegalPersonVatForm.vue";
import { backendErrorSwal } from "@/core/helpers/swal";
import CompanyVatService from "@/core/services/company/company-vat.service";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import Swal from "sweetalert2";

export default {
  components: {
    LegalPersonVatForm,
  },

  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
    // If true it emits the submit event instead of calling the API.
    emitSubmit: {
      type: Boolean,
      default: false,
    },
    // Used only if emitSubmit is true.
    saving: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    existingForm: {
      type: Object,
      default: () => ({}),
    },
    physicalPersonId: {
      type: Number,
      default: null,
    }
  },

  data() {
    return {
      form: null,
      isSaving: false,
      apiErrors: null,
    };
  },

  computed: {
    savingComputed: {
      get() {
        if (this.emitSubmit) {
          return this.saving;
        }
        return this.isSaving;
      },
      set(value) {
        this.isSaving = value;
      },
    },
    isEdit() {
      return Object.keys(this.existingForm).length > 0
    },
    isCompany() {
      return this.physicalPersonId == null
    }
  },

  watch: {
    apiErrors: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {
          country: [...(val?.country ?? [])],
          company: [...(val?.company ?? [])],
          vat: [...(val?.vat ?? [])],
          from_date: [...(val?.from_date ?? [])],
          to_date: [...(val?.to_date ?? [])],
          attachment_tus: [...(val?.attachment_tus ?? [])],
          counterpart: [...(val?.counterpart ?? [])],
        };
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  methods: {
    closeModal() {
      this.$bvModal.hide('create-vat-modal');
    },

    onModalHidden() {
      this.form = null;
    },

    async submit() {
      this.$refs.formComponent.updateForm();
      if (this.emitSubmit) {
        this.$emit("submit", {
          vat: { ...this.form, company: this.legalPerson?.id },
          closeModal: () => this.closeModal(),
        });
        return;
      }
      this.isSaving = true;
      try {
        if (this.isCompany) {
          if (this.isEdit) {
            await CompanyVatService.update(this.form, this.form.id)
          } else {
            await CompanyVatService.create({ ...this.form, company: this.legalPerson?.id });
          }
        } else {
          if (this.isEdit) {
            await CompanyVatService.update(this.form, this.form.id)
          } else {
            await PhysicalPersonService.individualCompany(this.physicalPersonId, this.form)
          }
        }

        this.$emit("refresh");
        this.closeModal();

        let title = this.$t("Document created")
        if (this.isEdit) {
          title = this.$t("Document Updated")
        }
        Swal.fire(this.$t("Success"), title, "success");
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          let message = err?.response?.data?.detail ?? this.$t("Failed to create the document. Please try again.")
          if (this.isEdit) {
            message = err?.response?.data?.detail ?? this.$t("Failed to update the document. Please try again.")
          }
          backendErrorSwal(message, err);
        }
      } finally {
        this.isSaving = false;
      }
    },

    onLegalPersonVatFormInput(form) {
      this.form = form;
    },
  },
};
</script>
