var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('Country'),
      "vid": "country",
      "rules": _vm.rules.country
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "label": _vm.$t('Country') + '*',
            "error-messages": errors,
            "options": _vm.countriesSelect,
            "searchable": ""
          },
          model: {
            value: _vm.form.country,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "country", $$v);
            },
            expression: "form.country"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "vid": "from_date",
      "name": _vm.$t('From date'),
      "rules": _vm.rules.from_date
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.fromDateOverrides,
            "error-messages": errors,
            "label": _vm.$t('From date')
          },
          model: {
            value: _vm.form.from_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "from_date", $$v);
            },
            expression: "form.from_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('To date'),
      "vid": "to_date",
      "rules": _vm.rules.to_date
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('date-picker', {
          attrs: {
            "overrides": _vm.toDateOverrides,
            "error-messages": errors,
            "label": _vm.$t('To date')
          },
          model: {
            value: _vm.form.to_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "to_date", $$v);
            },
            expression: "form.to_date"
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": "vat",
      "rules": _vm.rules.vat
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "vat",
            "error-messages": errors,
            "placeholder": _vm.form.country ? "".concat(_vm.form.country === 'GR' ? 'EL' : _vm.form.country, "XXXXXXXXXX") : _vm.$t('Enter VAT with country code')
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between"
              }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("VAT")) + "* "), _vm.viesValidation ? _c('i', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: {
                    "hover": true
                  }
                }],
                staticClass: "far fa-check-circle",
                style: {
                  color: '#00CBA8'
                },
                attrs: {
                  "title": _vm.$t('VIES valid')
                }
              }) : _vm._e()]), _vm.lastViesValidationDate ? _c('i', {
                class: _vm.icons.infoCircle,
                attrs: {
                  "id": "vies-validation-info"
                }
              }) : _vm._e(), _c('b-tooltip', {
                attrs: {
                  "target": "vies-validation-info"
                }
              }, [_c('strong', [_vm._v(_vm._s(_vm.$t("Last saved VIES validation")) + ":")]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.moment(_vm.lastViesValidationDate).format(_vm.dateFormat.concat(" HH:mm:ss"))))])])], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form.vat,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "vat", $$v);
            },
            expression: "form.vat"
          }
        })];
      }
    }])
  })], 1), !_vm.optional ? _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    staticClass: "w-100",
    attrs: {
      "name": _vm.$t('Attachment'),
      "vid": "attachment_tus",
      "rules": _vm.rules.attachment
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var _vm$form, _vm$form$vat, _vm$form2, _vm$form2$vat;
        var errors = _ref5.errors;
        return [_c('div', {
          attrs: {
            "id": "attachmentFile"
          }
        }, [_c('euro-tus-file-input', {
          attrs: {
            "label": _vm.$t('File attachment'),
            "vid": "attachment_tus",
            "error-messages": errors,
            "disabled": !((_vm$form = _vm.form) !== null && _vm$form !== void 0 && (_vm$form$vat = _vm$form.vat) !== null && _vm$form$vat !== void 0 && _vm$form$vat.length),
            "accept": "application/pdf",
            "hint": _vm.$t('Accept .pdf - max size 20MB'),
            "attachment": _vm.existingForm.attachment
          },
          on: {
            "change": _vm.onAttachmentChange
          }
        }), !((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && (_vm$form2$vat = _vm$form2.vat) !== null && _vm$form2$vat !== void 0 && _vm$form2$vat.length) ? _c('b-tooltip', {
          attrs: {
            "target": "attachmentFile"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Enter the VAT number first")) + " ")]) : _vm._e()], 1)];
      }
    }], null, false, 2397394089)
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }